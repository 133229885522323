import { useCallback, useState } from 'react';
import BrowserPersistence from "@magento/peregrine/lib/util/simplePersistence";
var useIsGcPDP = () => {
  var [isGcPdp, setIsGcPdp] = useState(() => sessionStorage.getItem('isGraphCommercePdp') || null);
  var savePdpSource = useCallback(() => {
    if (!isGcPdp) {
      sessionStorage.setItem('isGraphCommercePdp', 'true');
      setIsGcPdp(true);
    }
  }, [isGcPdp]);
  var getPDPUrl = url => {
    if (url === '/ultimate-blackout-shade.html') {
      var storage = new BrowserPersistence();
      var currentCartId = storage.getItem('cartId');
      return sessionStorage.getItem('isGraphCommercePdp') === 'true' ? "".concat(process.env.GRAPHCOMMERCE_PDP_URL, "?cart_id=").concat(currentCartId) : '/ultimate-blackout-shade.html';
    }
    return url;
  };
  return {
    savePdpSource,
    getPDPUrl
  };
};
export default useIsGcPDP;